<template>
  <Nav :position="position" />

  <main class="main__content">
    <div class="hero__section">
      <div class="hero__section-content">
        <h1>
          We help with<br>
          <TypedText
            :strings="['Study Visa.', 'Work permit.', 'Immigration consultation.', 'Tourist Visa.', 'Flight booking.']"
            :typeSpeed="100" :backSpeed="50" :loop="true" />
        </h1>
        <div class="hero__section-content--cta">
          <a href="https://forms.gle/F2LbF2GrY8bmKiMq5" class="primary-cta">
            Start your application
          </a>
          <a href="https://wa.link/f2cm9b">
            <i class="pi pi-whatsapp"></i>
            Talk to a consultant
          </a>
        </div>
      </div>
      <div class="overlay"></div>
      <div class="background__hero">
        <img id="heroImage" :src="require(`../assets/hero-images/${imageList[currentIndex]}`)" alt="">
      </div>
    </div>

    <div class="about__section">
      <div class="content">
        <h2>
          About SQNTT
        </h2>
        <p>
          SQ Travel and Tour (SQNTT) is a leading travel agency dedicated to providing exceptional travel experiences
          since established in 2024, headquartered in LAGOS STATE, NIGERIA.

          We're here to offer you an unforgettable travel experience that will stay with you for a lifetime! Our expert
          team is dedicated to creating tailor-made itineraries for every budget and taste. We offer an array of travel
          packages, including Education Counseling,
          Course Selection,
          University...
          <router-link to="/about" style="text-decoration: underline;">Learn more</router-link>
        </p>
        <img :src="require('../assets/about-shot.jpg')" alt="">
      </div>

      <div class="ab-search" id="ab-embedded-search" data-host="https://www.applyboard.com" data-rp-ref="72310"
        data-orientation="vertical" data-default-countries="Australia,Canada,United Kingdom,Ireland,USA"></div>
    </div>

    <div class="path__section">
      <h2>Our Pathways</h2>

      <div class="slide-container">
        <swiper-container id="swiper" :space-between="50" :navigation="true" :pagination="true" :loop="true"
          :centered-slides="true" :breakpoints="{
            768: {
              slidesPerView: 3,
            },
          }" :autoplay="true">
          <swiper-slide v-for="slide in slidesInfo" id="swiperSlide">
            <img :src="require(`../assets/${slide.imgPath}`)" alt="">
            <h3>{{ slide.title }}</h3>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="coverage__section">
      <div class="content">
        <h2>Our Coverage</h2>
        <p>We cover a hosts of different countries.<br>No matter your needs we got you.</p>
      </div>

      <div class="country-grid">
        <div class="country" v-for="country in countries">
          <router-link :to="`/coverage/${country.id}`">
            <img :src="require(`../assets/${country.name.split('_').join(' ')}.jpg`)" :alt="`${country.name}`">
            <h3>{{ country.name.split('_').join(" ") }}</h3>
          </router-link>
        </div>
      </div>
    </div>

    <div class="apply__section">
      <h2>Do you need help with your studies, <br> work, tourist visa, or medical?</h2>
      <form id="apply__form" @submit.prevent="sendEmailByClient">
        <label for="full-name">Full name</label>
        <input v-model="userName" id="full-name" type="text" required>
        <label for="email">Email address</label>
        <input v-model="userEmail" id="email" type="email" required autocomplete="email">
        <label for="tel-no">Whatsapp Contact</label>
        <input v-model="userNumber" id="tel-no" type="tel" required>
        <label for="country">Your country</label>
        <input v-model="userCountry" id="country" type="text" required autocomplete="country">
        <label for="message">Message</label>
        <textarea v-model="userMessage" id="message" cols="30" rows="10" required></textarea>
        <button type="submit">
          Submit
        </button>
      </form>
    </div>
  </main>
  <Footer />
</template>

<script setup>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import { ref, onMounted } from 'vue'
import TypedText from '@/components/TypedText.vue'


const position = ref('absolute')

const userName = ref(''),
  userEmail = ref(''),
  userNumber = ref(''),
  userMessage = ref(''),
  userCountry = ref('')

const imageList = ref([
  'image-one.jpg',
  'image-two.jpg',
  'image-three.jpg',
  'image-four.jpg',
  'image-five.jpg'
])

const currentIndex = ref(0)

let intervalDuration = 5000

const slidesInfo = ref([
  {
    title: "Immigration Consulation",
    imgPath: 'immigration.jpg'
  },
  {
    title: "Study Permit",
    imgPath: 'study.jpg'
  },
  {
    title: "Job Placement Assitance",
    imgPath: 'work.jpg'
  },
  {
    title: "Family Sponsorships & Reunification",
    imgPath: 'family.jpg'
  },
  {
    title: "Business and Investment Programs",
    imgPath: 'investment.jpg'
  },
  {
    title: "Tourist Visa",
    imgPath: 'tourist.jpg'
  },
  {
    title: "Birthing Visa",
    imgPath: 'birthing.jpg'
  },
  {
    title: "Visiting Visa",
    imgPath: 'visiting.jpg'
  },
])

const countries = ref([
  {
    id: 'UK',
    name: 'United_Kingdom'
  },
  {
    id: 'US',
    name: 'United_States'
  },
  {
    id: 'CA',
    name: 'Canada'
  },
  {
    id: 'IE',
    name: 'Ireland'
  },
  {
    id: 'AU',
    name: 'Australia'
  },
  {
    id: 'FR',
    name: 'France'
  },
  {
    id: 'NL',
    name: 'Netherlands'
  },
  {
    id: 'CY',
    name: 'Cyprus'
  },
  {
    id: 'GE',
    name: 'Germany'
  },
  {
    id: 'NZ',
    name: 'New_Zealand'
  },
])

function changeImage() {
  currentIndex.value = (currentIndex.value + 1) % imageList.value.length
}

function startImageLoop() {
  setInterval(changeImage, intervalDuration)
}

const sendEmailByClient = () => {
  const name = encodeURIComponent(userName.value),
    email = encodeURIComponent(userEmail.value),
    phoneNo = encodeURIComponent(userNumber.value),
    country = encodeURIComponent(userCountry.value),
    message = encodeURIComponent(userMessage.value)

  const emailBody = `Dear Team,%0A%0AI am reaching out with the following details:%0A%0A` +
    `Name: ${name}%0A` +
    `Email: ${email}%0A` +
    `Whatsapp Number: ${phoneNo}%0A` +
    `Country: ${country}%0A%0A` +
    `Message:%0A${message}%0A%0A` +
    `Thank you for your time and consideration.%0A%0ABest regards,%0A${name}`;

  const mailtoLink = `mailto:sqntravelntour@gmail.com?subject=New Enquiry ${name}&body=${emailBody}`;
  window.location.href = mailtoLink

  userName.value = ''
  userEmail.value = ''
  userCountry.value = ''
  userNumber.value = ''
  userMessage.value = ''
}

onMounted(() => {
  startImageLoop()
})
</script>

<style lang="scss">
.main__content {
  @include flex(column, center, center, 2em, nowrap);
  margin-inline: auto;
  width: 100dvw;

  .hero__section {
    position: relative;
    width: 100%;
    min-height: 100dvh;
    @include flex(column, flex-start, center, 0, nowrap);
    padding-inline: calc($page-padding--left) calc($page-padding--right);

    &-content {
      z-index: 101;
      @include flex(column, flex-start, flex-start, 1em, nowrap);

      h1 {
        color: $text-muted;
        font-size: var(--font-size-fluid-3);
      }

      &--cta {
        @include flex(row, center, flex-start, 1em, nowrap);

        a {
          padding: 12px 24px;
          background-color: #25D366;
          border-radius: var(--radius-2);
          color: $text-muted;
          font-weight: 800;
          font-size: var(--font-size-2);

          &.primary-cta {
            color: $text-secondary;
            background-color: #F4D35E;
          }
        }
      }
    }

    .overlay {
      width: 100dvw;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      background-image: linear-gradient(to right, rgba(69, 165, 211, .8), transparent);
    }

    .background__hero {
      position: absolute;
      left: 0;
      top: 0;
      width: 100dvw;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .about__section {
    min-height: 90dvh;
    width: 80%;
    @include flex(row, flex-start, space-between, 0, wrap);
    padding-inline: calc($page-padding--left / 2) calc($page-padding--right / 2);

    .content {
      width: 40%;
      @include flex(column, flex-start, flex-start, 1em, nowrap);

      h2 {
        font-size: 48px;
      }

      p {
        line-height: var(--font-lineheight-4);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: var(--radius-2);
      }
    }

    .ab-search {
      box-shadow: var(--shadow-1)
    }
  }

  .path__section {
    @include flex(column, center, center, 2em, nowrap);
    padding-block: var(--size-7);
    min-height: 90dvh;
    width: 100%;
    background-color: $color-primary-darker;
    padding-inline: var(--size-4);

    h2 {
      font-size: 48px;
      color: $text-muted;
    }

    .slide-container {
      width: 100%;
      height: 60dvh;

      #swiper {
        width: 100%;
        height: 100%;

        #swiperSlide {
          text-align: center;

          img {
            width: 90%;
            height: 80%;
            object-fit: cover;
            border-radius: var(--radius-3);
            clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
          }

          h3 {
            color: $text-muted;
          }
        }
      }
    }
  }

  .coverage__section {
    min-height: 90dvh;
    width: 90%;
    @include flex(column, flex-start, flex-start, 1em, nowrap);

    .content {
      @include flex(column, flex-start, flex-start, .5em, nowrap);

      h2 {
        font-size: 48px;
      }
    }

    .country-grid {
      width: 100%;
      @include flex(row, flex-start, flex-start, 2em, wrap);

      .country {
        width: calc(33.3% - 2em);
        padding: .5em;
        background-color: #eee;
        border-radius: var(--radius-2);
        box-shadow: var(--shadow-1);

        &:hover {
          background-color: #ddd;
          box-shadow: none;
        }

        a {
          width: 100%;

          h3 {
            font-size: 24px;
          }

          img {
            width: 100%;
            height: 300px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .apply__section {
    width: 100%;
    @include flex(column, center, center, 1em, nowrap);
    padding-block: var(--size-7);
    min-height: 50dvh;
    background-color: $color-primary-darker;
    padding-inline: var(--size-4);

    h2 {
      font-size: 33px;
      color: $text-muted;
      text-align: center;
    }

    form {
      width: 60%;
      @include flex(column, flex-start, flex-start, .3em, nowrap);
      padding: 12px;
      box-shadow: var(--shadow-2);
      background-color: $color-primary-darker;

      label {
        color: $text-muted;
      }

      input,
      button,
      textarea {
        width: 100%;
        padding-block: 12px;
        border-radius: var(--radius-2);
        border: none;
        padding-inline: 8px;
        color: $text-muted;
      }

      input,
      textarea {
        background-color: transparent;
        outline: solid var(--border-size-1) $color-primary;
      }

      button {
        background-color: $color-primary;
        margin-top: 20px;
        font-size: var(--font-size-2);
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main__content {
    .about__section {
      width: 100%;
      gap: 1em;

      .content {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 925px) {
  .main__content {
    .coverage__section {
      flex-direction: column;
      gap: 1em;

      .country-grid {
        width: 100%;
      }
    }

    .apply__section {
      form {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .main__content {
    .path__section {
      h2 {
        font-size: 33px;
      }

      .slide-container {
        #swiper {
          #swiperSlide {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .coverage__section {
      .country-grid {
        .country {
          width: calc(50% - 1em);
        }
      }
    }
  }
}

@media (max-width: 618px) {
  .main__content {
    .hero__section {
      padding-inline: calc($page-padding--left /2) calc($page-padding--right /2);

      &-content {
        width: 100%;

        h1 {
          font-size: 48px;
        }

        &--cta {
          width: 100%;
          flex-direction: column;
          gap: .5em;

          a {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .about__section {
      .content {
        h2 {
          font-size: 33px;
        }
      }
    }

    .coverage__section {

      .content {
        h2 {
          font-size: 33px;
        }
      }

      .country-grid {
        .country {
          width: calc(100% - 1em);

          a {
            h3 {
              font-size: var(--font-size-2);
            }
          }
        }
      }
    }
  }
}
</style>
